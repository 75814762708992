import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ModalDefesaPageRoutingModule } from './modal-defesa-routing.module';

import { ModalDefesaPage } from './modal-defesa.page';

import { MatStepperModule } from '@angular/material/stepper';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ModalDefesaPageRoutingModule,
        MatStepperModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        MatDividerModule,
        MatSelectModule,
        MatDatepickerModule,
        MatRadioModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        RecaptchaFormsModule,
        RecaptchaModule
    ],
    declarations: [ModalDefesaPage]
})
export class ModalDefesaPageModule { }
