import { version } from '../../package.json';

export const environment = {
    production: false,
    ambiente: window["env"]["ambiente"],
    variaveis: window["env"]["variaveis"],
    protocol: window["env"]["protocol"],
    host: window["env"]["host"],
    port: window["env"]["port"],
    context: window["env"]["context"],
    version: version
};
