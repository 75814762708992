import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';

import { ModalCetranPageRoutingModule } from './modal-cetran-routing.module';

import { ModalCetranPage } from './modal-cetran.page';
import { MatStepperModule } from '@angular/material/stepper';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ModalCetranPageRoutingModule,
        MatStepperModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        MatDividerModule,
        MatSelectModule,
        MatDatepickerModule,
        MatRadioModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        RecaptchaFormsModule,
        RecaptchaModule
    ],
    declarations: [ModalCetranPage]
})
export class ModalCetranPageModule { }
