import { Component, ElementRef, HostBinding, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {
  screenSize: Screen = window.screen;
    
  _src: SafeResourceUrl;
  @Input() set src(url) {
    this._src = this._sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  @ViewChild("outsideElement", { static: true }) outsideElement: ElementRef;
  @ViewChild('modalView', { static: true }) modalView$: ElementRef;
  @ViewChild('object', { static: false }) object$: ElementRef;

  constructor(
    private _sanitizer: DomSanitizer,
    private _modalController: ModalController
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    console.log(this._src);
  }

}
