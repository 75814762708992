import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LoadingController, ModalController, ToastController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';
import { FormUtils } from 'src/tools/form-utils';

@Component({
    selector: 'app-modal-auth',
    templateUrl: './modal-auth.page.html',
    styleUrls: ['./modal-auth.page.scss'],
})
export class ModalAuthPage implements OnInit {
    loginForm: FormGroup;
    constructor(private formBuilder: FormBuilder,
        private loadingController: LoadingController,
        private auth: AuthService,
        private modalController: ModalController,
        private toastController: ToastController
        ) { }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            nrPlaca: new FormControl('', Validators.required),
            nrRenavan: new FormControl('', Validators.required),
        })
    }

    async onSubmit() {
        let loading = await this.loadingController.create({
            message: 'Buscando...',
            spinner: 'bubbles'
        });
        await loading.present();
        await this.auth.autenticar((this.loginForm.value.nrPlaca).replace('-',''), this.loginForm.value.nrRenavan)
        await loading.dismiss();
        
        let toast;
        if (this.auth.verificar()) {
            this.modalController.dismiss();
            toast = await this.toastController.create({ message: "Autenticação realizada", duration: 3000 });
        } else {
            toast = await this.toastController.create({ message: "Não foi possível autenticar", duration: 3000 });
        }
        toast.present();
        //this.modalController.dismiss();
    }

    maskNrPlaca(event: KeyboardEvent) {
        const inputElement = event.target as HTMLInputElement;
        const currentValue = inputElement.value;
        FormUtils.maskNrPlaca(event, currentValue);
    }

    justNumbers(control: string) {
        const formControl = this.loginForm.get(control);
        if(formControl) {
            const justNumber = formControl.value.replace(/[^0-9]/g, '');
            formControl.setValue( justNumber, {emitEvent: false})
        }
    }
}
