import { NgModule, InjectionToken } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { RecaptchaFormsModule } from 'ng-recaptcha';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ModalApresentacaoPageModule } from './pages/servicos/apresentacao-condutor/modal-apresentacao/modal-apresentacao.module';
import { MatNativeDateModule } from '@angular/material/core';
import { ModalDefesaPageModule } from './pages/servicos/defesa-previa/modal-defesa/modal-defesa.module';
import { ModalRecursoPageModule } from './pages/servicos/recurso-jari/modal-recurso/modal-recurso.module';
import { ModalRestituicaoPageModule } from './pages/servicos/restituicao-multa/modal-restituicao/modal-restituicao.module';

import { NgxViacepModule } from '@brunoc/ngx-viacep';
import { ModalCetranPageModule } from './pages/servicos/recurso-cetran/modal-cetran/modal-cetran.module';
import { ModalAuthPageModule } from './pages/modal-auth/modal-auth.module';
import { ProtocoloService } from './services/protocolo.service';

import { PdfViewerComponent } from './shared/pdf-viewer/pdf-viewer.component';
import { Window } from './window';

export function windowFactory(): any {
    return window;
}

@NgModule({
    declarations: [AppComponent, PdfViewerComponent],
    entryComponents: [PdfViewerComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        ModalApresentacaoPageModule,
        ModalRecursoPageModule,
        ModalRestituicaoPageModule,
        ModalDefesaPageModule,
        ModalCetranPageModule,
        ModalAuthPageModule,
        MatNativeDateModule,
        RecaptchaFormsModule,
        NgxViacepModule
    ],
    providers: [
        StatusBar,
        SplashScreen,
        ProtocoloService,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { 
            provide: Window,
            useFactory: windowFactory
        }
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
