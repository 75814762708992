import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    CURRENT_URL_API: any;
    constructor(
        private http: HttpClient,
        private _snackbar: ToastController
    ) {
        this.buildApiUrl();
    }

    buildApiUrl (): void {
        this.CURRENT_URL_API = `${environment.protocol}://${environment.host}${environment.port ? ':' + environment.port : ''}/${environment.context}/rws/`;
        return;
    }

    post(endpoint, body, auth?) {
        return new Promise<any>((done, error) => {
            this.http.post(this.CURRENT_URL_API + endpoint, body, {
                headers: new HttpHeaders(auth ?
                    {
                        'Content-Type': 'application/json;charset=UTF-8',
                        'Authorization': 'Bearer ' + sessionStorage.getItem('auth-token')
                    } : {
                        'Content-Type': 'application/json;charset=UTF-8'
                    })
            })
                .pipe(
                    catchError((e) => throwError(async () => {
                        (await this._snackbar.create({
                            message: e.detail,
                            buttons: ["OK"]
                        }));
                        return new Error(e);
                    }))
                )
                .subscribe(dados => {
                    done(dados)
                }, err => {
                    error(err)
                });
        })
    }

    postPrint(endpoint, body) {
        return new Promise<any>((done, error) => {
            this.http.post(this.CURRENT_URL_API + endpoint, body, {
                headers: new HttpHeaders(
                    {
                        'Content-Type': 'application/json'
                    }),
                responseType: 'blob'
            }).subscribe(dados => {
                done(dados)
            }, err => {
                error(err)
            });
        })
    }


    get(endpoint = '', auth?) {
        return new Promise<any>((done, error) => {
            this.http.get(this.CURRENT_URL_API + endpoint, {
                headers: new HttpHeaders(auth ?
                    {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem('auth-token')
                    } : {
                        'Content-Type': 'application/json',
                    })
            }).subscribe(dados => {
                done(dados)
            }, async err => {
                if (err.error.code == -3) {
                    let token = await this.post('str/aits/auth', { nrPlaca: sessionStorage.getItem('nrPlaca'), nrRenavan: sessionStorage.getItem('nrRenavan') });
                    this.salvar('auth-token', token.detail);
                    this.get(endpoint, auth).then(a => {
                        done(a)
                    }).catch(b => {
                        error(b)
                    })
                } else {
                    error(err)
                }
            })
        })
    }

    getCepDefault(endpoint = '', auth?) {
        return new Promise<any>((done, error) => {
            this.http.get(this.CURRENT_URL_API + endpoint, {
                headers: new HttpHeaders(auth ?
                    {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem('auth-token')
                    } : {
                        'Content-Type': 'application/json',
                    })
            }).subscribe(dados => {
                done(dados)
            }, err => {
                err.error = JSON.parse(new TextDecoder().decode(err.error));
                error(err)
            });
        })
    }

    getFile(endpoint, auth?) {
        return new Promise<any>((done, error) => {
            this.http.get(this.CURRENT_URL_API + endpoint, {
                headers: new HttpHeaders(auth ?
                    {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem('auth-token')
                    } : {
                        'Content-Type': 'application/json',
                        'Accept': 'application/pdf'
                    }),
                responseType: 'arraybuffer'
            }).subscribe(dados => {
                done(dados)
            }, err => {
                err.error = JSON.parse(new TextDecoder().decode(err.error));
                error(err)
            });
        })
    }

    salvar(key, value) {
        sessionStorage.setItem(key, value);
    }

    async getParametro(nmParametro: string) {
        return this.get(`v2/grl/parametros/${nmParametro}/valor?legado=true`);
    }

}
