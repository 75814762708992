(function (window) {
    window['env'] = window['env'] || {};
    window['env']['ambiente'] = 'producao';
    window['env']['protocol'] = 'http';
    window['env']['host'] = 'localhost';
    window['env']['port'] = '8080';
    window['env']['context'] = 'mymanager';

    window['env']['variaveis'] = {
        producao: {
            nome: 'Portal do SIMTRANS', // Títul da página
            nmOrgao: 'SECRETARIA DE MOBILIDADE', //  Nome do órgão
            sgOrgao: 'SIMTRANS/SEMOB', // Sigla do órgão
            API: 'http://localhost:8080/etransito/rws/', // endpoint da API
            arquivos: 'conquista', // Diretório na pasta assets que ficam os formulários
            logo: true, // Aparece a logo?
            recursos: {
                andamentos: true, // Consultas de andamentos de AITs
                apresentacao: true, // Apresentação de condutor
                defesa: true, // Registro de defesa
                recurso: true, // Registro de recurso
                recurso_cetran: true, // Registro de recurso ao CETRAN
                vaga_estacionamento: true, // Vaga de estacionamento
                cartao_idoso: true, // Cartão do Idoso
                necessidades_especiais: true, //Necessidades Especiais
                restituicao: true, // Restituição
                impressao: true, // Impressão da AIT
                validacao: true, // 
            },
            links_externos: { // Links externos (fora do domínio do portal atual)
                links: [
                    { icon: 'car-sharp', titulo: 'e-DAT', href: '#link' },
                    { icon: 'headset-outline', titulo: 'Ouvidoria', href: '#link' },
                    { icon: 'search', titulo: 'Transparência', href: '#link' },
                ],
            },
            formularios: { // Formulários disponíveis para download
                apresentacaoCondutor: [
                    //{ titulo: 'Apresentação de Condutor', arquivo: 'apresentacao_condutor-PF.pdf' },
                    { titulo: 'Apresentação de Condutor (PF)', arquivo: 'apresentacao_condutor-PF.pdf' },
                    { titulo: 'Apresentação de Condutor (PJ)', arquivo: 'apresentacao_condutor-PJ.pdf' }
                ],
                defesaPrevia: [
                    { titulo: 'Formulário Defesa Prévia', arquivo: 'defesa.PDF' },
                    // { titulo: 'Defesa Prévia (PF)', arquivo: 'arquivo.pdf' },
                    // { titulo: 'Defesa Prévia (PJ)', arquivo: 'arquivo.pdf' }
                ],
                jari: [
                    { titulo: 'Formulário JARI', arquivo: 'formulario_jari.pdf' }
                ],
                cetran: [
                    { titulo: 'Formulário CETRAN', arquivo: 'form_cetran.pdf' }
                ]
            },
        },
    };
})(this);
