import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ModalApresentacaoPageRoutingModule } from './modal-apresentacao-routing.module';

import { ModalApresentacaoPage } from './modal-apresentacao.page';
import { MatStepperModule } from '@angular/material/stepper';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ModalApresentacaoPageRoutingModule,
        MatStepperModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        MatDividerModule,
        MatSelectModule,
        MatRadioModule,
        MatCheckboxModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        RecaptchaFormsModule,
        RecaptchaModule,
        MatAutocompleteModule,
        MatIconModule
    ],
    declarations: [ModalApresentacaoPage]
})
export class ModalApresentacaoPageModule { }
