import { Injectable, Inject } from '@angular/core';
import { ApiService } from './api.service';
import { Window } from '../window';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	constructor(
        @Inject(Window) private _window: any,
		public api: ApiService,
	) { }

	verificar() {
		if (sessionStorage.getItem('auth-token'))
			return true;
		else
			return false;
	}

	async autenticar(nrPlaca:String, nrRenavan: String) {
		try{
			
			let token = await this.api.post('str/aits/auth', { nrPlaca: nrPlaca.toUpperCase(), nrRenavan: nrRenavan.toUpperCase() });
			this.salvar('auth-token', token.detail);
			this.salvar('nrPlaca', nrPlaca);
			this.salvar('nrRenavan', nrRenavan);

		} catch(error){
            console.log(error);
			if(error.text)	{
				
			}
		}
	}

	salvar(key, value){
		this._window.sessionStorage.setItem(key, value);
    }
    
    async limpar(){
        this._window.sessionStorage.removeItem('auth-token');
        this._window.sessionStorage.removeItem('nrPlaca');
        this._window.sessionStorage.removeItem('nrRenavan');
    }


}
