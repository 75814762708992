import imageCompression from 'browser-image-compression';

export class ImageCompressor {
    async compress(file: File) {
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
            maxIteration: 10
        };
        return await imageCompression(file, options);
    }
}