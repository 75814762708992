import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Platform } from '@ionic/angular';
import { filter, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
    public ambiente = environment.variaveis[environment.ambiente];
    public version = environment.version;
    public selectedIndex = 0;
    public selectedIndexServicos = 0;
    public appPages = [
        {
            title: 'Início',
            url: 'pages/home',
            icon: 'home'
        }
    ];
    public appPagesServicos = [
        {
            title: 'Andamentos de AIT',
            url: 'pages/servicos/andamentos-ait',
            icon: 'list',
            recurso: 'andamentos'
        },
        {
            title: 'Apresentação do Condutor',
            url: 'pages/servicos/apresentacao-condutor',
            icon: 'person-add',
            recurso: 'apresentacao'
        },
        {
            title: 'Defesa Prévia',
            url: 'pages/servicos/defesa-previa',
            icon: 'list',
            recurso: 'defesa'
        },
        {
            title: 'Recurso à JARI',
            url: 'pages/servicos/recurso-jari',
            icon: 'reader',
            recurso: 'recurso'
        },
        {
            title: 'Recurso ao CETRAN',
            url: 'pages/servicos/recurso-cetran',
            icon: 'receipt',
            recurso: 'recurso_cetran'
        },
        {
            title: 'Vaga Especial',
            icon: 'car',
            recurso: 'vaga_estacionamento',
            chieldren: [
                {
                    title: 'Cartão do Idoso',
                    url: 'pages/servicos/cartao-idoso',
                    recurso: 'cartao_idoso'
                },
                {
                    title: 'Cartão PcD',
                    url: 'pages/servicos/necessidades-especiais',
                    recurso: 'necessidades_especiais'
                },
            ]
        },
        {
            title: 'Restituição',
            url: 'pages/servicos/restituicao-multa',
            icon: 'cash',
            recurso: 'restituicao'
        },
        {
            title: 'Imprimir BOAT',
            url: 'pages/servicos/impressao-boat',
            icon: 'print',
            recurso: 'impressao'
        },
        {
            title: 'Validar BOAT',
            url: 'pages/servicos/validacao-boat',
            icon: 'checkbox',
            recurso: 'validacao'
        },

    ];

    public current;

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private router: Router
    ) {
        this.initializeApp();
        this.router.events.pipe(

            filter((e): e is NavigationEnd => e instanceof NavigationEnd),

            map(e => {                
                if (e.url == '/pages/home' || e.url == '/')
                    this.current = 'home'
                else {
                    this.selectedIndex = null;
                    this.appPagesServicos.forEach((el, i) =>{
                        if('/'+el.url == e.url)
                            this.selectedIndexServicos = i
                    })
                    this.current = ''
                }

            })).subscribe();
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        });
    }

    ngOnInit() {
        const path = window.location.pathname.split('folder/')[1];
        if (path !== undefined) {
            this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
        }
    }
}
