export class FormUtils{

    public static maskNrPlaca(event: KeyboardEvent, value: string) {
        const key = event.key;
        const length = value.length;
        const regexPatterns = [
            /^[A-Za-z]$/,
            /^[0-9]$/,
            /^[A-Za-z0-9]$/
        ];

        let patternIndex;
        if (length <= 2) patternIndex = 0;
        else if (length === 3) patternIndex = 1;
        else if (length === 4) patternIndex = 2;
        else patternIndex = 1;

        if (!regexPatterns[patternIndex].test(key)) {
            event.preventDefault();
        }
    }
}