import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { LoadingController, ModalController, ToastController } from '@ionic/angular';
import * as moment from 'moment';
import { Ait } from 'src/app/interfaces/ait';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import * as VMasker from 'vanilla-masker/';

@Component({
    selector: 'app-modal-restituicao',
    templateUrl: './modal-restituicao.page.html',
    styleUrls: ['./modal-restituicao.page.scss'],
})
export class ModalRestituicaoPage implements OnInit {
    @ViewChild('stepper') stepper;
    @ViewChild('nrCPFRequerente', { read: ElementRef }) nrCPFRequerente: ElementRef;
    
    public buscarAit: FormGroup;
    public dadosRequerente: FormGroup;
    public imagensForm: FormGroup;
    public autenticado: boolean = false;
    private tiposArquivos: Array<any>;
    public ait: Ait;
    private endpoint = "str/aits";
    public UFs = ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO']
    public imagens = [];
    constructor(
        private formBuilder: FormBuilder,
        private api: ApiService,
        private auth: AuthService,
        private loadingController: LoadingController,
        private toastController: ToastController,
        private modalController: ModalController) { }

    async ngOnInit(
    ) {
        this.buscarAit = this.formBuilder.group({
            nrAit: new FormControl('', Validators.required),
            nrPlaca: new FormControl('', Validators.required),
            recaptchaReactive: new FormControl(null)
        })
        this.dadosRequerente = this.formBuilder.group({
            nmRequerente: new FormControl('', Validators.compose([Validators.required, Validators.minLength(6)])),
            nrCPFRequerente: new FormControl('', Validators.compose([Validators.required, Validators.minLength(14), Validators.maxLength(18)])),
            nrRgRequerente: new FormControl(''),
            dsEndereco: new FormControl(''),
            nrEndereco: new FormControl(''),
            dsComplemento: new FormControl(''),
            nmMunicipio: new FormControl(''),
            ufCnhRequerente: new FormControl(''),
            txtRequisicao: new FormControl('')
        });

        this.imagensForm = this.formBuilder.group({
            checkRegistroCPF: new FormControl('', Validators.required),
            checkRegistroRG: new FormControl('', Validators.required),
            checkRegistroCart: new FormControl('', Validators.required),
            checkRegistroPgmt: new FormControl('', Validators.required),
            checkRegistroCompr: new FormControl('', Validators.required),
            checkRegistroForm: new FormControl('', Validators.required),
            tpRegistroCPF: new FormControl(''),
            tpRegistroRG: new FormControl(''),
            tpRegistroCart: new FormControl(''),
            tpRegistroRes: new FormControl(''),
            tpRegistroPgmt: new FormControl(''),
            tpRegistro: new FormControl(''),
        })

        if (!this.auth.verificar()) {
            this.autenticado = false;
        } else {
            this.autenticado = true;
            this.buscarAit.patchValue({ nrPlaca: sessionStorage.getItem('nrPlaca'), nrRenavan: sessionStorage.getItem('nrRenavan') });
        }
        try {
            this.tiposArquivos = await this.api.get('v2/grl/arquivos/tipos', true);
            
            if(!(this.tiposArquivos instanceof Array) || this.tiposArquivos.length === 0) {
                (await this.toastController.create({message: 'Tipos de arquivos não cadastrados, entre em contato com o suporte para a solução do problema.', buttons: [{text: 'OK'}]})).present();
                this.modalController.dismiss();
            }
        } catch (error) {

        }
    }

    async buscar(stepper: MatStepper) {
        let loading = await this.loadingController.create({ message: "Buscando AIT..." });
        loading.present();

        if (!this.autenticado) {
            await this.auth.autenticar((this.buscarAit.value.nrPlaca).replace('-',''), this.buscarAit.value.nrRenavan)
        }
        try {
            let consulta = "placa=" + (this.buscarAit.value.nrPlaca).replace('-','') + (this.buscarAit.value.nrAit ? "&ait=" + this.buscarAit.value.nrAit : '');
            let retorno = await this.api.get(this.endpoint + '?' + consulta, true);
            this.ait = retorno[0];
            loading.dismiss();
            stepper.next();
        } catch (error) {
            loading.dismiss();
            let toast = await this.toastController.create({ message: "Não foi possível encontrar o AIT", duration: 3000 });
            toast.present();
        }
    }

    async avancar(stepper: MatStepper) {
        stepper.next();
    }

    carregarImagens(idElemento) {
        var target = document.getElementById(idElemento)
        target.click()
    }

    abreArquivos(e, tipo, tipoCheck) {

        var files = e.target.files;
        if (!files.length) { return; }
        for (let file of files) {
            var reader = new FileReader();
            reader.onload = (e) => {
                var image = new Image();
                image.src = <any>e.target['result'];
                image.onload = async () => {
                    let imageReturn = await this.comprimir(e.target['result'])
                    this.imagensForm.controls[tipoCheck].setValue(true);
                    let imagem = {
						baixa_resolucao: this._base64ToArrayBuffer(imageReturn),
                        cdTipoArquivo: this.getTPArquivo(tipo)
                    }
                    this.imagens.push(imagem)
                }
            };
            reader.readAsDataURL(file);
        }
    }
	
    _base64ToArrayBuffer(base64) {
        base64 = base64.split(',')[1];
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return Array.from(bytes);
    }

    comprimir(img?) {
        return new Promise<any>(done => {
            let imagemOtimizada = null;
            var image = new Image();
            image.src = img;
            image.onload = () => {
                var canvas = document.createElement('canvas'),
                    ctx = canvas.getContext('2d');
                canvas.width = image.naturalWidth;
                canvas.height = image.naturalHeight;

                if (canvas.width > 1080 && canvas.width > canvas.height) {
                    let proporcao = (((1080 * 100) / canvas.width)) / 100;
                    canvas.width = Math.round(image.naturalWidth * proporcao);
                    canvas.height = Math.round(image.naturalHeight * proporcao);

                } else if (canvas.height > 1080 && canvas.height > canvas.width) {
                    let proporcao = (((1080 * 100) / canvas.height)) / 100;
                    canvas.height = Math.round(image.naturalHeight * proporcao);
                    canvas.width = Math.round(image.naturalWidth * proporcao);
                }
                ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
                imagemOtimizada = canvas.toDataURL("image/jpeg", 0.9);
                done(imagemOtimizada);
            };
        });
    }

    getTPArquivo(tipo: string) {
        let tp = this.imagensForm.value[tipo] ? this.imagensForm.value[tipo] : tipo
        for (let tpArquivo of this.tiposArquivos) {
            if (tpArquivo.nmTipoArquivo == tp) {
                return tpArquivo.cdTipoArquivo
            }
        }
        return 0
    }

    async finalizar() {
        const loading = await this.loadingController.create({
            message: 'Enviando..',
            duration: 2000,
            spinner: 'bubbles'
        });
        try {
            await loading.present();
            if (!this.autenticado) {
                await this.auth.autenticar((this.buscarAit.value.nrPlaca).replace('-',''), this.buscarAit.value.nrRenavan)
            }
            let imagens = []

            this.imagens.forEach(async (imagem, index) => {
                let arquivo = {
                    nmArquivo: 'Arquivo-' + index + '.jpg',
                    nmDocumento: 'Arquivo-' + index + '.jpg',
                    dtArquivamento: moment().format('YYYY-mm-DDTHH:mm:ss'),
                    blbArquivo: null,
                    strArquivo: imagem.baixa_resolucao,
                    cdTipoArquivo: imagem.cdTipoArquivo
                }
                imagens.push(arquivo);
            })
            let requerente;
            requerente = {
                cdRequerente: 0,
                nmRequerente: this.dadosRequerente.value.nmRequerente,
                nrCpfRequerente: this.dadosRequerente.value.nrCPFRequerente,
                nrTelefone1Requerente: this.dadosRequerente.value.nrTelefone,
                dsEnderecoRequerente: this.dadosRequerente.value.dsEndereco,
                nrEnderecoRequerente: this.dadosRequerente.value.nrEndereco,
                nmCidadeRequerente: this.dadosRequerente.value.nmMunicipio,
                ufCnhRequerente: this.dadosRequerente.value.ufCnhRequerente,
            }
            // if (this.buscarAit.value.tpRequerente == 'Condutor') {
            // } else {
            //     requerente = {
            //         cdRequerente: 0,
            //         nmCondutor: this.dadosRequerente.value.nmRequerente,
            //         nrCpfCondutor: this.dadosRequerente.value.nrCPFRequerente,
            //         nrTelefone1Condutor: this.dadosRequerente.value.nrTelefone,
            //         dsEnderecoCondutor: this.dadosRequerente.value.dsEndereco,
            //         nrEnderecoCondutor: this.dadosRequerente.value.nrEndereco,
            //         nmCidadeCondutor: this.dadosRequerente.value.nmMunicipio,
            //         ufCnhCondutor: this.dadosRequerente.value.ufCnhRequerente,
            //     }
            // }
            let dados = {
                cdAit: this.ait.cdAit,
                cdProcesso: 0,
                dtProcesso: moment().format('YYYY-MM-DDTHH:mm:ss'),
                stProcesso: 0,
                tpProcesso: 33,
                arquivos: imagens,
                requerente: requerente
            }
            let envio = await this.api.post(this.endpoint + '/' + this.ait.cdAit + '/processos', dados, true);
            let toast = await this.toastController.create({ message: "Solicitação de restituição entregue com sucesso", duration: 3000 });
            toast.present();
            await loading.dismiss();
            this.modalController.dismiss();
            // let arquivo = await this.api.post(this.endpoint + '/' + this.ait.cdAit + '/processos/formularios/7', requerente);
            // let arquivo2
            // if(arquivo.status == 200){
            //     if(arquivo.error)
            //     arquivo2 = (new Blob([ await arquivo.error.text], { type: 'application/pdf' }));

            // }
            
            // if (arquivo2.size > 0) {
            //     const link = document.createElement('a');
            //     // create a blobURI pointing to our Blob
            //     link.href = URL.createObjectURL(arquivo2);
            //     link.download = 'Defesa Prévia - '+this.ait.cdAit+'.pdf';
            //     // some browser needs the anchor to be in the doc
            //     document.body.append(link);
            //     link.click();
            //     link.remove();
            // } else {
            //     //
            // }
        } catch (error) {
            console.error(error);
            let toast = await this.toastController.create({ message: "Não foi possível enviar esta solicitação.", duration: 3000 });
            toast.present();
            await loading.dismiss();
        } 
    }

    dismiss() {
        this.modalController.dismiss()
    }

    momentFormat(date){
        return moment(date).format('DD/MM/YYYY')
    }

    setMasks(e){
        setTimeout(() => {
            var maskCpfCnpj = ['999.999.999-99', '99.999.999/9999-99'];
            var nrCPFRequerente = this.nrCPFRequerente.nativeElement;
            
            this.dadosRequerente.get('nrCPFRequerente').valueChanges.subscribe(value => {
                nrCPFRequerente.addEventListener('input', this.inputHandler.bind(undefined, maskCpfCnpj, 11), false);
            })
            

          }, 1000);
          
    }

    inputHandler(masks, max, event) {
        var c = event.target;
        var v = c.value.replace(/\D/g, '');
        var m = v.length > max ? 1 : 0;
        VMasker(c).unMask();
        VMasker(c).maskPattern(masks[m]);
        c.value = VMasker.toPattern(v, masks[m]);
    }
}
