import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'pages/home',
    pathMatch: 'full'
  },
  {
    path: 'pages/home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'pages/servicos/apresentacao-condutor',
    loadChildren: () => import('./pages/servicos/apresentacao-condutor/apresentacao-condutor.module').then( m => m.ApresentacaoCondutorPageModule)
  },
  {
    path: 'pages/servicos/andamentos-ait',
    loadChildren: () => import('./pages/servicos/andamentos-ait/andamentos-ait.module').then( m => m.AndamentosAITPageModule)
  },
  {
    path: 'pages/servicos/defesa-previa',
    loadChildren: () => import('./pages/servicos/defesa-previa/defesa-previa.module').then( m => m.DefesaPreviaPageModule)
  },
  {
    path: 'pages/servicos/recurso-jari',
    loadChildren: () => import('./pages/servicos/recurso-jari/recurso-jari.module').then( m => m.RecursoJariPageModule)
  },
  {
    path: 'pages/servicos/cartao-idoso',
    loadChildren: () => import('./pages/servicos/cartao-idoso/cartao-idoso.module').then( m => m.CartaoIdosoPageModule)
  },
  {
    path: 'pages/servicos/necessidades-especiais',
    loadChildren: () => import('./pages/servicos/necessidades-especiais/necessidades-especiais.module').then( m => m.NecessidadesEspeciaisPageModule)
  },
  {
    path: 'pages/servicos/restituicao-multa',
    loadChildren: () => import('./pages/servicos/restituicao-multa/restituicao-multa.module').then( m => m.RestituicaoMultaPageModule)
  },
  {
    path: 'pages/servicos/impressao-boat',
    loadChildren: () => import('./pages/servicos/impressao-boat/impressao-boat.module').then( m => m.ImpressaoBoatPageModule)
  },
  {
    path: 'pages/servicos/validacao-boat',
    loadChildren: () => import('./pages/servicos/validacao-boat/validacao-boat.module').then( m => m.ValidacaoBoatPageModule)
  },
  {
    path: 'pages/servicos/recurso-cetran',
    loadChildren: () => import('./pages/servicos/recurso-cetran/recurso-cetran.module').then( m => m.RecursoCetranPageModule)
  },
  {
    path: 'modal-auth',
    loadChildren: () => import('./pages/modal-auth/modal-auth.module').then( m => m.ModalAuthPageModule)
  },
  {
    path: 'protocolo',
    loadChildren: () => import('./pages/protocolo/protocolo.module').then( m => m.ProtocoloPageModule)
  }






];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
